import { useVehicleModelStore } from "@/stores/useVehicleModelStore";
import useLogger from "@/composables/useLogger";

export default defineNuxtRouteMiddleware((to, from) => {
  const logger = useLogger("ensureVehicleModel");
  const store = useVehicleModelStore();

  logger.debug("Checking if quote has started");
  if (!store.quoteHasStarted()) {
    logger.info("Quote not started or reset, navigating to /");
    // no infinite loop
    if (to.path !== "/") {
      return navigateTo("/");
    }
  }
});
